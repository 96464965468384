// src/api.js
import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://api.okurentals.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set up an interceptor to include the token in all requests
api.interceptors.request.use(
  (config) => {
    // Get the access token from localStorage
    const token = localStorage.getItem('user_access_token');

    // Include the token only if it exists and if the request is not for login or register
    if (token && !config.url.includes('/login/') && !config.url.includes('/register/')) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
