import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Grid, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../api';

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    anotherPhoneNumber: '',
    email: '',
    address: '',
    nicNumber: '',
    nicFront: null,
    nicBack: null,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when the request starts

    const data = new FormData();
    data.append('name', formData.name);
    data.append('phone_number', formData.phoneNumber);
    data.append('secondary_phone_number', formData.anotherPhoneNumber);
    data.append('email', formData.email);
    data.append('address', formData.address);
    data.append('nic_number', formData.nicNumber);
    data.append('nic_front_image', formData.nicFront);
    data.append('nic_back_image', formData.nicBack);

    try {
      const response = await api.post('/user/register/', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setSuccessMessage('Registration successful! Redirecting to login...');
        setErrorMessage('');
        setSnackbarOpen(true);
        setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
      }
    } catch (error) {
      const detailedMessage = error.response?.data?.detail || 'Registration failed. Please try again.';
      setErrorMessage(detailedMessage);
      setSuccessMessage('');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Set loading state to false when the request completes
    }
  };

  return (
    <Container maxWidth="xs" sx={{ marginTop: 4, padding: 4, backgroundColor: '#f9f9f9', borderRadius: 3 }}>
      <Typography variant="h5" color="primary" textAlign="center" gutterBottom>
        Register User
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Full Name" name="name" size="small" fullWidth required value={formData.name} onChange={handleChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Primary Phone Number" name="phoneNumber" size="small" fullWidth required value={formData.phoneNumber} onChange={handleChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Secondary Phone Number" name="anotherPhoneNumber" size="small" fullWidth value={formData.anotherPhoneNumber} onChange={handleChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Email Address" name="email" size="small" fullWidth value={formData.email} onChange={handleChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Residential Address" name="address" size="small" fullWidth required value={formData.address} onChange={handleChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="NIC Number" name="nicNumber" size="small" fullWidth required value={formData.nicNumber} onChange={handleChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <Typography>NIC Front Image</Typography>
            <input type="file" name="nicFront" accept="image/*" required onChange={handleFileChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <Typography>NIC Back Image</Typography>
            <input type="file" name="nicBack" accept="image/*" required onChange={handleFileChange} disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Register'}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity={successMessage ? 'success' : 'error'}>{successMessage || errorMessage}</Alert>
      </Snackbar>
    </Container>
  );
}

export default Register;
