// src/components/Login.js
import React, { useState, useContext } from 'react';
import { TextField, Button, Container, Typography, Grid, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { UserContext } from '../UserContext';

function Login() {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [formData, setFormData] = useState({ nicNumber: '', phoneNumber: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the request starts

    console.log("Attempting to login with: ", formData); // Log form data for debugging

    try {
      const response = await api.post('/user/login/', {
        nic_number: formData.nicNumber,
        phone_number: formData.phoneNumber,
      });

      console.log("Login Response: ", response); // Log the response for debugging

      if (response.status === 200) {
        setSuccessMessage('Login successful!');
        setErrorMessage('');

        // Save the access token to local storage for future requests
        localStorage.setItem('user_access_token', response.data.access); // Save the access token
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Save user data to localStorage
        setUser(response.data.user); // Set user data in context

        setSnackbarOpen(true);
        setTimeout(() => navigate('/dashboard'), 2000); // Redirect to dashboard after 2 seconds
      }
    } catch (error) {
      console.error("Login Error: ", error); // Log error for debugging
      setErrorMessage(error.response?.data?.message || 'Invalid credentials!');
      setSuccessMessage('');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Set loading to false when the request completes
    }
  };

  return (
    <Container maxWidth="xs" sx={{ marginTop: 4, padding: 4, backgroundColor: '#f9f9f9', borderRadius: 3 }}>
      <Typography variant="h5" color="primary" textAlign="center" gutterBottom>
        User Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="NIC Number"
              name="nicNumber"
              size="small"
              fullWidth
              required
              value={formData.nicNumber}
              onChange={handleChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Primary Phone Number"
              name="phoneNumber"
              type="password"
              size="small"
              fullWidth
              required
              value={formData.phoneNumber}
              onChange={handleChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity={successMessage ? 'success' : 'error'}>{successMessage || errorMessage}</Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;
