// src/components/Navbar.js
import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext'; // Import UserContext correctly
import logo from '../assets/logo.png';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, setUser, loading } = useContext(UserContext); // Ensure UserContext is used correctly
  const navigate = useNavigate();

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user_access_token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: '#ffffff', boxShadow: 'none' }}>
      <Toolbar>
        <img src={logo} alt="Logo" style={{ height: '50px', marginRight: 'auto', cursor: 'pointer' }} onClick={() => navigate('/')} />

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : !user ? (
            <>
              <NavLink to="/register">
                <Button color="primary" sx={{ marginRight: '8px' }}>
                  Register
                </Button>
              </NavLink>
              <NavLink to="/login">
                <Button color="primary">Login</Button>
              </NavLink>
            </>
          ) : (
            <>
              <Button component={NavLink} to="/dashboard" color="primary" sx={{ marginRight: '8px' }}>
                Dashboard
              </Button>
              <IconButton
                color="primary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%', padding: '8px' }}
              >
                <AccountCircle />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => setAnchorEl(null)}>Account Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
