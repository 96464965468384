// src/App.js
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider, Container, CircularProgress } from '@mui/material';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import { UserContext, UserProvider } from './UserContext'; // Import UserContext and UserProvider
import theme from './theme';

function AppContent() {
  const { user, setUser, loading, setLoading } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check for a saved token and user data in localStorage
    const token = localStorage.getItem('user_access_token');
    const userData = localStorage.getItem('user');

    // If token and user data exist, set the user in context and skip login
    if (token && userData) {
      setUser(JSON.parse(userData));
    } else {
      // Redirect to login if no session is found
      navigate('/login');
    }

    setLoading(false); // Set loading to false after checking session
  }, [setUser, setLoading, navigate]);

  if (loading) {
    // Show loading spinner while the session is being checked
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: '20px' }}>
      <Routes>
        <Route path="/" element={user ? <Dashboard /> : <Login />} /> {/* Redirect to Dashboard if logged in, else show Login */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={user ? <Dashboard /> : <Login />} /> {/* Redirect to Dashboard if logged in, else show Login */}
        <Route path="/dashboard" element={user ? <Dashboard /> : <Login />} /> {/* Protect Dashboard route */}
      </Routes>
    </Container>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router>
          <Navbar />
          <AppContent />
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
