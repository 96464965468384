// src/UserContext.js
import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext(); // Create a new context

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // State to store user information
  const [loading, setLoading] = useState(true); // Loading state to handle initialization

  useEffect(() => {
    // Initialize user state from localStorage
    const storedUser = localStorage.getItem('user');
    const token = localStorage.getItem('user_access_token');

    if (storedUser && token) {
      setUser(JSON.parse(storedUser)); // Set user data in context from localStorage
    }
    setLoading(false); // Set loading to false after initialization
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, setLoading }}>
      {children}
    </UserContext.Provider>
  );
};
