// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#071a35', // Primary color (dark blue)
      contrastText: '#ffffff', // White text for buttons
    },
    secondary: {
      main: '#495156', // Secondary color (gray)
      contrastText: '#ffffff',
    },
    error: {
      main: '#eb293a', // Error color (red)
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

export default theme;

