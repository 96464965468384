// src/components/UserDashboard.js
import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, Card, CardContent, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import api from '../api';

// Styled Paper component for uniform card styling
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const UserDashboard = () => {
  const [userData, setUserData] = useState(null);
  const [currentRentals, setCurrentRentals] = useState([]);
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [rentalHistory, setRentalHistory] = useState([]);

  useEffect(() => {
    // Fetch user status after login and other rental details
    const fetchUserData = async () => {
      try {
        const userResponse = await api.get('/user/status/');
        setUserData(userResponse.data);

        const rentalsResponse = await api.get('/user/current-rentals/');
        setCurrentRentals(rentalsResponse.data);

        const reservationsResponse = await api.get('/user/upcoming-reservations/');
        setUpcomingReservations(reservationsResponse.data);

        const historyResponse = await api.get('/user/rental-history/');
        setRentalHistory(historyResponse.data);
      } catch (error) {
        console.error('Error fetching user data or rentals:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 8 }}>
      {/* Welcome Header */}
      <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Welcome, {userData?.name || 'User'}!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Manage your rentals and account information here.
        </Typography>
      </Box>

      {/* Overview Section */}
      <Grid container spacing={4}>
        {/* Current Rentals Card */}
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Current Rentals
            </Typography>
            {currentRentals.length > 0 ? (
              currentRentals.map((rental, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1" fontWeight="bold">
                    {rental.machine_name}
                  </Typography>
                  <Typography variant="body2">Start Date: {rental.start_date}</Typography>
                  <Typography variant="body2">End Date: {rental.end_date}</Typography>
                  <Typography variant="body2">Status: {rental.status}</Typography>
                  <Button variant="outlined" color="primary" size="small" sx={{ mt: 1 }}>
                    Extend Rental
                  </Button>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No active rentals.
              </Typography>
            )}
          </StyledPaper>
        </Grid>

        {/* Upcoming Reservations Card */}
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Upcoming Reservations
            </Typography>
            {upcomingReservations.length > 0 ? (
              upcomingReservations.map((reservation, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1" fontWeight="bold">
                    {reservation.machine_name}
                  </Typography>
                  <Typography variant="body2">Reservation Date: {reservation.date}</Typography>
                  <Typography variant="body2">Confirmation: {reservation.confirmation_status}</Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No upcoming reservations.
              </Typography>
            )}
          </StyledPaper>
        </Grid>

        {/* Account Information Card */}
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            {userData ? (
              <>
                <Typography variant="body1" fontWeight="bold">
                  {userData.name}
                </Typography>
                <Typography variant="body2">Email: {userData.email}</Typography>
                <Typography variant="body2">Phone: {userData.phone_number}</Typography>
                <Typography variant="body2">Address: {userData.address}</Typography>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Loading account details...
              </Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Rental Booking and History Section */}
      <Box mt={4}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Rental Booking and History
        </Typography>
        <Grid container spacing={4}>
          {/* Book New Rental Button */}
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>
                Book New Rental
              </Typography>
              <Button variant="contained" color="primary">
                Start New Rental
              </Button>
            </StyledPaper>
          </Grid>

          {/* Rental History Section */}
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>
                Rental History
              </Typography>
              {rentalHistory.length > 0 ? (
                rentalHistory.map((rental, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant="body1" fontWeight="bold">
                      {rental.machine_name}
                    </Typography>
                    <Typography variant="body2">Rental Period: {rental.start_date} - {rental.end_date}</Typography>
                    <Typography variant="body2">Amount Paid: {rental.amount_paid}</Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No past rentals found.
                </Typography>
              )}
            </StyledPaper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserDashboard;
